import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AphidAI from '../images/AphidAI.png';
import Spinner from '../images/spinner.gif';
import flowerOne from '../images/flowerOne.png';
import flowerTwo from '../images/flowerTwo.png';
import { useNavigate } from 'react-router-dom';

const MainPage = ({ setIsAuthenticated }) => {
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState('');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchFilter, setSearchFilter] = useState('title'); // Default filter
  const [showUserPosts, setShowUserPosts] = useState(false); // New state for filtering user posts
  const [commentText, setCommentText] = useState('');
  const [commentPostId, setCommentPostId] = useState(null);
  const navigate = useNavigate();

  const baseURL = 'https://plantshout-199a76bab95e.herokuapp.com';

  useEffect(() => {
    const fetchPosts = () => {
      const userId = localStorage.getItem('user_id');
      axios
        .get(`${baseURL}/api/posts`, {
          params: {
            q: searchQuery,
            filter_by: searchFilter,
            user_only: showUserPosts ? userId : null, // Send user ID if filtering by user
          },
        })
        .then(response => setPosts(response.data))
        .catch(error => console.error('Error fetching posts:', error));
    };
  
    fetchPosts(); // Initial fetch
  
    const interval = setInterval(fetchPosts, 5000); // Fetch new posts every 5 seconds
  
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [searchQuery, searchFilter, showUserPosts]);
  

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    navigate('/login');
  };

  const handleToggleUserPosts = () => {
    setShowUserPosts(!showUserPosts); // Toggle between all posts and user-only posts
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('category', category);
    formData.append('tags', tags);
    formData.append('title', title);
    formData.append('text', text);
    formData.append('user_id', localStorage.getItem('user_id'));
    if (image) {
      formData.append('image', image);
    } else {
      formData.append('image', null); // Handle the case of no image explicitly
    }
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios.post(`${baseURL}/api/posts`, formData, config);
      setCategory('');
      setTags('');
      setTitle('');
      setText('');
      setImage(null);
      axios.get(`${baseURL}/api/posts`).then((response) => setPosts(response.data)); // Refresh posts
    } catch (error) {
      console.error('Error creating post:', error);
    } finally {
      setLoading(false); // Hide loader after post creation
    }
  };

const handleCommentSubmit = async (event, postId) => {
    event.preventDefault();
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("user_id");
    const config = {
      headers:  {
        Authorization: `Bearer ${token}`,
      },
    };

  try {
    await axios.post(`${baseURL}/api/comments`, {
      text: commentText,
      post_id: postId,
      user_id: userId,
    }, config);
    setCommentText("");
    setCommentPostId(null)
    axios.get(`${baseURL}/api/posts`).then((response) => setPosts(response.data));
  } catch(error) {
    console.error("error adding comment: ", error);
  }
};

const handleToggleComplete = async (postId, currentStatus) => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    await axios.patch(
      `${baseURL}/api/posts`,
      { post_id: postId, completed: !currentStatus },
      config
    );
    // Refresh posts
    axios.get(`${baseURL}/api/posts`).then((response) => setPosts(response.data));
  } catch (error) {
    console.error('Error updating post:', error);
  }
};


const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png") && file.size <= 10485760) {
        setImage(file);
    } else {
        alert("Only JPEG or PNG images under 10MB are allowed.");
        setImage(null);
    }
};


  return (
    <div className="main-page">
      <div className="top_half">
      <header>
        <h1>Welcome to Plant Shout</h1>
        <button onClick={handleLogout}>Logout</button>
      </header>
      <div className="info">
        <h2> To get AphidAI's help just select question in the category section when making a post.
          </h2>
        <h2> To not get help from AphidAI, select discussion in the category section when making a post.
          </h2>
      </div>
      <div className="post-form">
        <form onSubmit={handleSubmit}>
          <h2>Create Post</h2>
          <select value={category} onChange={(e) => setCategory(e.target.value)} required>
            <option value="">Select Category</option>
            <option value="question">Question (AphidAI enabled)</option>
            <option value="discussion">Discussion (no AI)</option>
          </select>
          <input
            type="text"
            placeholder="Tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <textarea
            placeholder="Text Here"
            value={text}
            onChange={(e) => setText(e.target.value)}
            required
          ></textarea>
          {/*{<input type="file" onChange={(e) => setImage(e.target.files[0])} />}*/}
          <input type="file" onChange={handleImageChange} />
          {image && (
            <div className="image-preview">
              <img
                src={URL.createObjectURL(image)}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  marginTop: "10px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
          )} 
          {/* change ^^this^^ section when chatgpt api endpoint is fixed for images */}
          <button type="submit">Submit Post</button>
        </form>
        <h2> </h2>
        {/* Loader */}
        {loading && (
          <div className="loader">
            <img src={Spinner} alt="Loading..." />
          </div>
        )}
      </div>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search posts by title or tags here..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <select
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        >
          <option value="title">Title</option>
          <option value="tags">Tags</option>
        </select>
        <div className="toggle-user-posts" onClick={handleToggleUserPosts}>
          <img
            src={showUserPosts ? flowerTwo : flowerOne}
            alt={showUserPosts ? "Viewing Your Posts" : "Viewing All Posts"}
            className="toggle-image"
          />
          <p>{showUserPosts ? "Seeing Your Posts" : "Seeing All Posts"}</p>
        </div>
      </div>
      </div>
      <div className="feed">
        {posts.map((post) => (
          <div key={post.id} className={`post ${post.completed ? 'completed' : ''}`}>
            <div className="post-header">
              <div className="user-picture">
                <img
                  src={`${baseURL}/profile_pics/${post.user_profile_pic}`}
                  alt="Profile"
                />
              </div>
              <h2>{post.title}</h2>
              <label>
                <input
                  type="checkbox"
                  checked={post.completed}
                  onChange={() => handleToggleComplete(post.id, post.completed)}
                />
                Mark as Answered
              </label>
            </div>
            <h3>{post.text}</h3>
            {post.image && post.image !== 'null' && (
              <div className="post-image">
                <img
                  /*src={`${baseURL}/${post.image}`}*/
                  src={post.image}
                  alt="Post"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '400px',
                    marginTop: '10px',
                    borderRadius: '8px',
                  }}
                />
              </div>
            )}
            
            <div className="comment_divider">
              <h3>- - - Comments - - -</h3>
            </div>

            <form onSubmit={(e) => handleCommentSubmit(e, post.id)}>
              <textarea
              placeholder = "Write a comment here"
              value = {commentPostId === post.id ? commentText : ""}
              onChange = {(e) => {
                setCommentPostId(post.id);
                setCommentText(e.target.value)
              }}
              required
              ></textarea>
              <button type="submit">Post Comment</button>
            </form>
            <div className="comments">
              {post.comments &&
                post.comments.map((comment) => (
                  <div key={comment.id} className="comment">
                    <div className="user_picture">
                    <img src={`${baseURL}/profile_pics/${comment.user_profile_pic}`} alt="Profile" />
                    </div>
                    <p>{comment.text}</p>
                  </div>
                ))}
              <div className="comment">
                <div clssName = "AI_picture">
                  <img src={AphidAI} alt="AphidAI" />
                </div>
                <p>{post.ai_response}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainPage;


